$color_1: #2FD6AE;
$color_2: #060E14;
$color_3: #555;
$color_4: #999;
$color_5: #eeeeee;
$border_radius: 6px;
$font_14: 14px;
$font_16: 16px;
$padding_80: 80px 0;
$tran_3: all .3s ease-in-out;
$shadowBox: 0 0 10px rgba(0, 0, 0, 0.1019607843);

