@import './components/variables/variables.scss';
// here rules and regulations

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Cairo', sans-serif;
  color: $color_2;
  margin: 0;
  background: #060E14 !important;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;
}

::selection {
  background-color: $color_1 !important;
  color: white;
}

