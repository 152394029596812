@import "../../variables/variables.scss";
@import "../../variables/mixins.scss";
////// Footer Part

.footer-upper {
  background-color: black;
  padding: 30px 0;
  .sign {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      width: 25px;
      
    }
    p {
      font-weight: 500;
      text-transform: capitalize;
      margin: 0 10px;
    }
  }

  .Sub-email {
    .form-control:focus {
      box-shadow: none;
      border: none;
      
    }
    .form-control {
        padding: 5px;
        border: 1px solid #fff;
    }


    .form-control::placeholder {
      font-size: $font_14;
    }

    .input-group-append {
      .input-group-text {
        @include creatBtn;
        padding: 8px 0;
        margin-left: -10px;
        cursor: pointer;
        color: black;
        border: none;
        &:hover {
          color: white;
          background-color: $color_3;
          border: none;
        }
      }
    }
  }
}

.footer-center {
  background-color: black;
  color: white;
  padding: 50px 0;
  .txt-footer-center {
      width: 100%;
      h4 {
        text-transform: capitalize;
        font-size: 20px;
        margin-bottom: 30px;
      }
    .footer-center-links {
      width: 100%;
      .img-logo-footer {
        width: 100%;
        
       img {
         width: 90%;
         border-radius: $border_radius;
       }
      }
      a {
        color: #dadada;
        display: block;
        line-height: 2;
        font-size: $font_14;
        text-transform: capitalize;
        transition: $tran_3;
        &:hover {
          color: $color_1;
        }
      }

      .social_icons {
        .wrapper {
          display: flex;
          list-style: none;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
        
        .wrapper .icon {
          position: relative;
          background: $color_2;
          border-radius: 50%;
          padding: 15px;
          margin: 10px;
          width: 40px;
          height: 40px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 10px 10px rgba(34, 34, 34, 0.363);
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
        
        .wrapper .tooltip {
          position: absolute;
          top: 0;
          font-size: 14px;
          background: #ffffff;
          color: #ffffff;
          padding: 5px 8px;
          border-radius: 5px;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          opacity: 0;
          pointer-events: none;
          transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
        
        .wrapper .tooltip::before {
          position: absolute;
          content: "";
          height: 8px;
          width: 8px;
          background: #ffffff;
          bottom: -3px;
          left: 50%;
          transform: translate(-50%) rotate(45deg);
          transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
        
        .wrapper .icon:hover .tooltip {
          top: -45px;
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
        
        .wrapper .icon:hover span,
        .wrapper .icon:hover .tooltip {
          text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
        }
        
        .wrapper .facebook:hover,
        .wrapper .facebook:hover .tooltip,
        .wrapper .facebook:hover .tooltip::before {
          background: #1877F2;
          color: #ffffff;
        }
        
        .wrapper .twitter:hover,
        .wrapper .twitter:hover .tooltip,
        .wrapper .twitter:hover .tooltip::before {
          background: #1DA1F2;
          color: #ffffff;
        }
        
        .wrapper .instagram:hover,
        .wrapper .instagram:hover .tooltip,
        .wrapper .instagram:hover .tooltip::before {
          background: #E4405F;
          color: #ffffff;
        }
        
        .wrapper .github:hover,
        .wrapper .github:hover .tooltip,
        .wrapper .github:hover .tooltip::before {
          background: #333333;
          color: #ffffff;
        }
        
        .wrapper .youtube:hover,
        .wrapper .youtube:hover .tooltip,
        .wrapper .youtube:hover .tooltip::before {
          background: #CD201F;
          color: #ffffff;
        }
        
      }
    }
  }
}

.footer-bottom {
  background-color: black;
  color: white;

  .txt-co {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    p {
      margin: 0;
      font-size: $font_14;
    }
  }

}

[dir="rtl"] {
  .footer-upper {
    .Sub-email {
      .form-control {
          border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
      }
  
  
      .form-control::placeholder {
        font-size: $font_14;
      }
  
      .input-group-append {
        .input-group-text {
          margin-left: 0;
          margin-right: -10px;
        }
      }
    }
  }
  
  .footer-center {

    .txt-footer-center {
        width: 100%;
        h4 {
          font-size: 20px;
          font-weight: 600;
        }
      .footer-center-links {
      
        width: 100%;
        .img-logo-footer {
          width: 100%;
         img {
           width: 100%;
           border-radius: $border_radius;
         }
        }
        address {
          font-size: $font_16 !important;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 2;
          color: $color_4 !important;
          word-spacing: 2px;
        }
        a {
          display: block;
          transition: $tran_3;
          @include paraText;
          color: $color_4;
          &:hover {
            color: $color_1;
          }
        }
  
          
      }
    }
  }
  
}
////// Footer Part End Here
.bages_links {
  text-decoration: none;
}
@media (max-width: 575.98px)  {
.footer-upper{
  .sign{
    margin-bottom: 27px !important;
    text-align: center !important;
     display: flex !important; 
     justify-content: center!important;

  }
  .Sub-email{
    text-align: center !important;
  }
}
.footer-center{
  text-align: center !important;
  .txt-footer-center {
    margin: 20px 0;
    h4 {
      margin-bottom: 20px;
   }
  }
  .wrapper .icon {
    position: relative;
    background: $color_2;
    border-radius: 50%;
   text-align: center !important;
    margin: 10px !important;
    width: 30px !important;
    height:30px !important;
    font-size: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px !important;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.363);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
  .footer-upper{
    .sign{
      margin-bottom: 27px !important;
      text-align: center !important;
       display: flex !important; 
       justify-content: center!important;
  
    }
    .Sub-email{
      text-align: center !important;
    }
  }
  .footer-center{
    text-align: center !important;
    .txt-footer-center {
      margin: 20px 0;
      h4 {
         margin-bottom: 20px;
      }
    }
    .wrapper .icon {
      position: relative;
      background: $color_2;
      border-radius: 50%;
     text-align: center !important;
      margin: 10px !important;
      width: 30px !important;
      height:30px !important;
      font-size: 15px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px !important;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.363);
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) { 
  .footer-upper{
    .sign{
      margin-bottom: 27px !important;
      text-align: center !important;
       display: flex !important; 
       justify-content: center!important;
  
    }
    .Sub-email{
      text-align: center !important;
    }
  }
  .footer-center{
    text-align: center !important;
    .txt-footer-center {
      margin: 20px 0;
      h4 {
         margin-bottom: 20px;
      }
    }
    .wrapper .icon {
      position: relative;
      background: $color_2;
      border-radius: 50%;
     text-align: center !important;
      margin: 10px !important;
      width: 30px !important;
      height:30px !important;
      font-size: 15px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px !important;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.363);
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
 }



