@import '../../variables/variables.scss';
@import '../../variables/mixins.scss';


/************
 ////////// Navbar ////////
 ************/

 .navbar {
    padding: 1rem 0;
    width: 100%;
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .5s ease-in-out;
    &.active {
        background-color: $color-2;
        position: fixed;
        top: 0;
        left: 0;
        transition: $tran_3;
        padding: .5rem 0;
        border-bottom: 1px solid #a8a8a84b;
    }


    .language-in-responsive{
        display: none;
    }
    .navbar-toggler{
        background: none !important;
        border: none !important;
        outline: none !important;
        .icon{
            font-size: 35px;
            color: $color-1;
        }
    }
    .navbar-collapse {
        .navbar-nav {
            width: 83%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    gap: 30px;
        }
        .langs {
            display: flex;
            align-items: center;
                a {
                    @include creatBtn;
                    font-size: 13px;
                    text-decoration: none;
                    color: black;
                    font-weight: bold;
                    &:hover {
                        color: white;
                    }
                }
        }
    }
    .logo {
        width: 180px;
        object-fit: cover;
    }
    .nav-link {
        color: white;
        text-transform: uppercase;
        font-size: $font_14;
        font-weight: 500;
        cursor: pointer;
        padding: 0.7rem;
    }
    .navbar-nav .nav-link.active {
        color: $color-1;
    }
 }

 [dir="rtl"] {
    .navbar {
        .nav-link {
            font-size: $font_16;
        }
        .navbar-collapse {
            .langs {
                    a {
                        @include creatBtn;
                    }
            }
        }
     }
 }
 @media (max-width: 575.98px) {
    .language-in-responsive{
        display: block !important;
    } 
    .navbar-collapse {
        background-color: $color-2;
        padding: 30px 20px;
        .navbar-nav {
            width: 100% !important;
            margin-bottom: 20px;
            .nav-item {
                .nav-link {
                    font-size: 18px;
                }
            }
        }
    }
 }

 .other-page {
    position: relative;
}

 // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    .language-in-responsive{
        display: block !important;
    } 
    .navbar-collapse {
        background-color: $color-2;
        padding: 30px 20px;
        .navbar-nav {
            width: 100% !important;
            margin-bottom: 20px;
            .nav-item {
                .nav-link {
                    font-size: 18px;
                }
            }
        }
    }
 }

 // Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) { 
    .language-in-responsive{
        display: block !important;
    } 
    .navbar-collapse {
        background-color: $color-2;
        padding: 30px 20px;
        .navbar-nav {
            width: 100% !important;
            margin-bottom: 20px;
            .nav-item {
                .nav-link {
                    font-size: 20px;
                }
            }
        }
    }
 }