.eorr-section{
    min-height: 100vh;
    text-align: center;
   
   
        img{
          
            width: 90vw;
            max-width: 800px;
            display: block;
            margin-bottom: 2rem;
            margin-top: -3rem;
            margin: auto;
        }
     .content{
        text-align: center;
        h3 {
            margin-top: 10px;
        }
        p{
            margin-top: 20px;
            
        }
        .to_home{
            
        }
     }
  
}