@import "../variables/variables.scss";

.massage_section{
    width: 300px;
    height: 270px;
    margin-bottom: 10px;
    position: fixed;
    z-index: 9999;
    bottom: -400px;
    right: 10px;
    transition: all .5s ease-in-out;
    opacity: 0;
  
    .close_massage{
        border: none;
        outline: none;
       width: 30px;
       height: 30px;
       font-weight: bold;
       border-radius: 50%;
       font-size: 18px;
       margin-bottom: 10px;
       color: white;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: transparent;
       svg {
        color: #0e9c45;
        font-size: 30px;
        border: none;
        outline: none;
       }
    }
    .intrduce_massager{
        display: flex;
        gap: 10px;
      .content_massager{
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        .welcom_massage{
            width: 100%;
            padding: 15px;
            background-color: #0e9c45;
            color: white;
            text-align: center;
           
        }
        .name_of_respons{
            padding: 15px;
            background-color: white;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                color: rgb(163, 163, 163);
                font-size: 14px;
            }
            img {
                width: 30px;
            }
        }
        .massage_container{
            width: 100%;
            padding: 14px;
            background-color: white;
            color: white;
            display: flex;
            gap: 8px;
            font-size: 14px;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #ddd;
            .btn{
                border: none;
                background-color: transparent;
                outline: transparent;
                svg {
                    color: #0e9c45;
                    font-size: 20px;
                }
            }
            .input{
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                width: 80%;
                text-align: start;
                color:$color_2;
            }
        }
      }
    }

}

.active_massage {
  bottom: 20px;
  opacity: 1;

 }

 [dir='rtl'] {
    .massage_section{
        .close_massage{
            border: none;
        }
        .intrduce_massager{

          .content_massager{
            width: 100%;

            .welcom_massage{
                width: 100%;

               
            }
            .massage_container{
                width: 100%;
                a{
                    svg {
                        color: #0e9c45;
                        font-size: 20px;
                    }
                }
                .input{
                    text-align: start;
                }
            }
          }
        }
    
    }
 }
 .whats_button{

    border: none !important;
    outline: none !important;
    position: fixed !important;
    bottom: 10px !important;
    right: 10px !important;
    z-index: 99999 !important;
    box-shadow: 8px 8px 18px 0px rgba(42, 67, 113, 0.3) !important;
    transition: all 0.3s ease-in-out !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    padding: 10px 15px !important;
    line-height: 1 !important;
    margin: 0 !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    background: #0e9c45 !important;
    cursor: pointer !important;
    border: 1px solid transparent !important;
    outline: 0 !important;
    color: #fff !important;
    overflow: hidden !important;
    border-radius: 25px !important;
    -webkit-border-radius: 25px !important;
  }